import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Timer from "../../../components/NavButtons/TimeClock";

const scriptsChannel = new BroadcastChannel("script");

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // padding: 10,
    backgroundColor: "white",
    borderRadius: "10px",
  },
}));

export default function CenteredGrid(props) {
  const classes = useStyles();
  const [durationMin, setDurationMin] = useState(0);

  useEffect(() => {
    scriptsChannel.onmessage = (ev) => {
      if(ev.data){
        setDurationMin(ev.data.durationMin ? ev.data.durationMin : 0);
       }
    };
  }, []);

  useEffect(() => {
    const localDuration = JSON.parse(localStorage.slideScript);
    setDurationMin(localDuration ? localDuration.durationMin : 0);
  }, []);

  return (
    <div className={classes.root}>
      <Timer
        durationMin={durationMin}
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      />
    </div>
  );
}
