import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import QRCode from "react-qr-code";

import translations from '../../translations/welcomeTranslations'

// TODO create seperate style sheet to import to all slides!!!
const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  imageDiv: {
    // position: "relative",
    // paddingTop: "56.25%",
  },
  contentDiv: {
    paddingTop: "1.5rem",
  },
  text: {
    fontSize: "1.4rem",
    margin: "25px 0px 7px",
  },
  largeText: {
    fontSize: "1.6rem",
  },
  emphasize: {
    fontSize: "xx-large",
    color: "#2A7050",
    margin: "0px",
  },
  title: {
    color: "#2A7050",
    fontSize: "xx-large",
    margin: "0px 0px 25px 0px",
    width: "max-content",
  },
  gridContainer: {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  gridItem1: {
    paddingRight: "14vw",
    // flexBasis: "100%",
  },
  gridItem2: {
    // flexBasis: "100%",
  },
  topOfSectionText: {
    margin: "0px 0px 7px",
  },
  boxShadow: {
    border: "1px solid #b7b7b7",
    boxShadow: "20px 18px 12px #b7b7b7",
  },
  buttons: {
    bottom: "40px",
    left: "28px",
    position: "absolute",
    fontColor: "#b7b7b7",
    zIndex: "1",
    "button &": {
      color: "#00FF00",
    },
  },
});

const channel = new BroadcastChannel("playerControls");

const WelcomeSlide = ({ title, videoUrl, pin, lang }) => {
  const classes = useStyles();
  const videoRef = useRef();



  const playFunction = () => {
    var video = document.getElementById("myVideo");

      video.play();
      video.style.display = "block";
  };

  const pauseFunction = () => {
    var video = document.getElementById("myVideo");
      video.pause();
  };

  const backFunction = () => {
    var video = document.getElementById("myVideo");

    video.currentTime = video.currentTime - 10;
  };

  const forwardFunction = () => {
    var video = document.getElementById("myVideo");

    video.currentTime = video.currentTime + 10;
  };


  useEffect(() => {
    channel.onmessage = ev => {
      switch(ev.data) {
        case 'PLAY':
          return playFunction();
        case 'PAUSE':
          return pauseFunction();
        case 'back10':
          return backFunction();
        case 'forward10':
          return forwardFunction();
        default:
          return 'null';
      }

    };

    // return () => {
    //   channel.close();
    // };
  }, []);


  return (
    <Grid
      container
      alignItems="center"
      style={{
        // height: '100%',
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        margin: "4%",
      }}
    >
      <Grid
        item
        lg={4}
        style={{
          zIndex: 2,
          height: "76vh",
        }}
      >
        <div>
          {/* <h3 className={classes.topOfSectionText}>Welcome to the</h3>
          <p className={classes.title}>{title}</p> */}
          <h3 className={classes.text}>{translations[lang].translatorIs}</h3>
          <p className={classes.emphasize}>{translations[lang].anonymous}</p>
          <h3
            className={classes.text}
          >
            {translations[lang].visit}
          </h3>
          <p className={classes.emphasize}>www.join.host</p>
          <h3 className={classes.text}>{translations[lang].enterPin}</h3>
          <p className={classes.emphasize}>{pin}</p>

          <QRCode
            value={`www.join.host/event/${pin}`}
            style={{ marginTop: "50px" }}
            size={150}
          />

        </div>
      </Grid>
      <Grid item lg={8}>
        <video
          id="myVideo"
          ref={videoRef}
          style={{
            position: "fixed",
            right: "20px",
            bottom: "10px",
            maxWidth: "75%",
            maxHeight: "75%",
            display: "none",
          }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  );
};

WelcomeSlide.propTypes = {
  text: PropTypes.string,
  videoUrl: PropTypes.string,
  pin: PropTypes.number,
};

export default WelcomeSlide;
