import axios from "axios";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import WelcomeSlide from "./Slides/welcomeSlide";
import VideoSlide from "./Slides/videoSlide";
import DiscussionSlide from "./Slides/discussionSlide";
import ClosingSlide from "./Slides/closingSlide";
import ReportSlide from "./Slides/reportSlide";
import Default from "./Default";
import { PulseLoader } from "react-spinners";

const AutoHostSlide = ({
  moduleId,
  pin,
  sessionId,
  currentModuleId,
  wopModuleId,
  wordCloudModuleId,
  pollModuleId,
  modules,
  // setRightButtonText,
  isDemo,
  czModuleId,
  lang
}) => {
  const [module, setModule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_MODULE_SERVICE}/slide?id=${moduleId}`,
    })
      .then((res) => {
        setModule(res.data.data[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId]);

  if (!module.type) {
    return (
      <PulseLoader
        sizeUnit="px"
        size={15}
        color="#60A681"
        loading
        speedMultiplier={".5"}
      />
    );
  } else {
    switch (module.type) {
      case "welcome": {
        return (
          <WelcomeSlide
            title={module.title}
            videoUrl={module.videoUrl}
            pin={pin}
            lang={lang}
          />
        );
      }
      case "video": {
        return (
          <VideoSlide
            videoUrl={module.videoUrl}
            title={module.title}
            isLoading={isLoading}
            lang={lang}
          />
        );
      }
      case "report": {
        return (
          <ReportSlide
            moduleId={currentModuleId}
            sessionId={sessionId}
            reportType={module.reportType}
            wopModuleId={wopModuleId}
            wordCloudModuleId={wordCloudModuleId}
            pollModuleId={pollModuleId}
            czModuleId={czModuleId}
            moduleTitle={"moduleTitle"}
            isDemo={isDemo}
          />
        );
      }
      case "discussion": {
        return (
          <DiscussionSlide
            title={module.title}
            image={module.imageUrl}
            questions={module.slide_question_joins}
          />
        );
      }
      case "closing": {
        return (
          <ClosingSlide
            title={module.title}
            text={module.generalText}
            image={module.imageUrl}
            modules={modules}
          />
        );
      }
      default: {
        return <Default />;
      }
    }
  }
};

AutoHostSlide.propTypes = {
  moduleId: PropTypes.number.isRequired,
  pin: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  currentModuleId: PropTypes.number.isRequired,
  wopModuleId: PropTypes.number,
  wordCloudModuleId: PropTypes.number,
  pollModuleId: PropTypes.number,
  czModuleId: PropTypes.number,
  modules: PropTypes.array,
  // setRightButtonText: PropTypes.func.isRequired,
  isDemo: PropTypes.bool.isRequired,
};

export default AutoHostSlide;
