import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const scriptsChannel = new BroadcastChannel("script");

const useStyles = makeStyles((theme) => ({
script: {
    textAlign: "left",
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
}));

export default function CenteredGrid(props) {
  const classes = useStyles();
  const [modScript, setModScript] = useState("Not Available");

  useEffect(() => {
    scriptsChannel.onmessage = (ev) => {
      if(ev.data){
       setModScript(ev.data.script ? ev.data.script : "Not Available");
      }
    };
  }, []);

  useEffect(() => {
    const localScript = JSON.parse(localStorage.slideScript);
    setModScript(localScript ? localScript.script : "Not Available");
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: modScript }}
      className={classes.script}
    ></div>
  );
}
