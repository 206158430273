const translations =

  { es: {
        translatorIs: 'Translator es',
        anonymous: '100% anónimo',
        visit: 'En su teléfono por favor visite',
        enterPin: 'Introduce este pin',
        start: 'Comenzar',
        pause: 'Pausa',
    },
    en: {
        translatorIs: 'Translator is',
        anonymous: '100% anonymous',
        visit: 'On your phone please visit',
        enterPin: 'Enter this pin',
        start: 'START',
        pause: 'Pause',
    },
    fr: {
        translatorIs: 'Translator est',
        anonymous: '100% anonyme',
        visit: 'Sur votre téléphone, veuillez visiter',
        enterPin: 'Entrez cette broche',
        start: 'Commencer',
        pause: 'Pause',

    },

}



export default translations