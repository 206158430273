import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Logo from "../../assests/img/full-logo-color.png";
// import NavButtons from "../../components/CustomButtons/NavButtons";

import translations from './translations'

const useStyles = makeStyles({
  stats: {
    top: "20px",
    right: "20px",
    position: "absolute",
  },
  logo: {
    top: "20px",
    left: "20px",
    position: "absolute",
  },
  buttons: {
    bottom: "40px",
    right: "28px",
    position: "absolute",
    color: "#b7b7b7",
    zIndex: "1",
  },
  button: {
    color: "#b7b7b7",
  },
  li: {
    paddingTop: 0,
    paddingBottom: 0,
    zIndex: "1",
  },
});

function Stats(props) {
  const { liveQuestions, audienceUsers, channel, moduleId, lang
} =
    props;
  const [audienceUserCount, setAudienceUserCount] = useState(0);
  const [liveQuestionCount, setLiveQuestionCount] = useState(0);

  const classes = useStyles();

const showControls =() =>{

  window.open(`/controls?id=${moduleId}`, 'newPage', 'toolbar=0,menubar=0,width=450,height=920,left=300,top=200,scrollbars=1,resizable=1')
}
  useEffect(() => {
    if (channel) {
      channel.bind("audience-user-created", (au) => {
        setAudienceUserCount(au);
      });
      channel.bind("live-chat", (lq) => setLiveQuestionCount(lq));
    }
  }, [channel]);

  useEffect(() => {
    setAudienceUserCount(audienceUsers);
    setLiveQuestionCount(liveQuestions);
  }, [audienceUsers, liveQuestions]);


  return (
    <div>
      <div className={classes.logo}>
        <img src={Logo} alt="logo" height="55px" />
      </div>
      <List className={classes.stats}>
        <ListItem classes={{ root: classes.li }}>
          {audienceUserCount === 1 ? (
            <ListItemText primary={`${audienceUserCount} ${translations[lang].participant}`} />
          ) : (
            <ListItemText primary={`${audienceUserCount} ${translations[lang].participants}`} />
          )}
        </ListItem>
        <ListItem classes={{ root: classes.li }}>
          {liveQuestionCount === 1 ? (
            <ListItemText primary={`${liveQuestionCount}  ${translations[lang].share}`} />
          ) : (
            <ListItemText primary={`${liveQuestionCount} ${translations[lang].shares}`} />
          )}
        </ListItem>
      </List>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          onClick={()=> showControls()}
        >
          {translations[lang].controls}
        </Button>
      </div>
    </div>
  );
}

Stats.propTypes = {
  audienceUsers: PropTypes.number.isRequired,
  liveQuestions: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Stats;
