const translations =

  { es: {
        participant: 'Participante',
        participants: 'Participantes',
        share: 'Compartir',
        shares: 'Comparte',
        back: 'Atrás',
        next: 'Siguiente',
        pin: 'Pin',
        controls: 'Controles'

    },
    en: {
        participant: 'Participant',
        participants: 'Participants',
        share: 'Share',
        shares: 'Shares',
        back: 'Back',
        next: 'Next',
        pin: 'Pin',
        controls: 'Controls'

    },
    fr: {
        participant: 'Participant',
        participants: 'Participants',
        share: 'Partager',
        shares: 'Actions',
        back: 'Dos',
        next: 'Suivant',
        pin: 'Broche',
        controls: 'Contrôles'


    },

}



export default translations